/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBookingDraft = /* GraphQL */ `
  subscription OnCreateBookingDraft(
    $filter: ModelSubscriptionBookingDraftFilterInput
  ) {
    onCreateBookingDraft(filter: $filter) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookingDraft = /* GraphQL */ `
  subscription OnUpdateBookingDraft(
    $filter: ModelSubscriptionBookingDraftFilterInput
  ) {
    onUpdateBookingDraft(filter: $filter) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingDraft = /* GraphQL */ `
  subscription OnDeleteBookingDraft(
    $filter: ModelSubscriptionBookingDraftFilterInput
  ) {
    onDeleteBookingDraft(filter: $filter) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePetDraft = /* GraphQL */ `
  subscription OnCreatePetDraft($filter: ModelSubscriptionPetDraftFilterInput) {
    onCreatePetDraft(filter: $filter) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const onUpdatePetDraft = /* GraphQL */ `
  subscription OnUpdatePetDraft($filter: ModelSubscriptionPetDraftFilterInput) {
    onUpdatePetDraft(filter: $filter) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const onDeletePetDraft = /* GraphQL */ `
  subscription OnDeletePetDraft($filter: ModelSubscriptionPetDraftFilterInput) {
    onDeletePetDraft(filter: $filter) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const onCreateBlog = /* GraphQL */ `
  subscription OnCreateBlog($filter: ModelSubscriptionBlogFilterInput) {
    onCreateBlog(filter: $filter) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBlog = /* GraphQL */ `
  subscription OnUpdateBlog($filter: ModelSubscriptionBlogFilterInput) {
    onUpdateBlog(filter: $filter) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBlog = /* GraphQL */ `
  subscription OnDeleteBlog($filter: ModelSubscriptionBlogFilterInput) {
    onDeleteBlog(filter: $filter) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking(
    $filter: ModelSubscriptionBookingFilterInput
    $owner: String
  ) {
    onCreateBooking(filter: $filter, owner: $owner) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        items {
            petType
            petName
            petNeeds
        }
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking(
    $filter: ModelSubscriptionBookingFilterInput
    $owner: String
  ) {
    onUpdateBooking(filter: $filter, owner: $owner) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking(
    $filter: ModelSubscriptionBookingFilterInput
    $owner: String
  ) {
    onDeleteBooking(filter: $filter, owner: $owner) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePet = /* GraphQL */ `
  subscription OnCreatePet(
    $filter: ModelSubscriptionPetFilterInput
    $owner: String
  ) {
    onCreatePet(filter: $filter, owner: $owner) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const onUpdatePet = /* GraphQL */ `
  subscription OnUpdatePet(
    $filter: ModelSubscriptionPetFilterInput
    $owner: String
  ) {
    onUpdatePet(filter: $filter, owner: $owner) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const onDeletePet = /* GraphQL */ `
  subscription OnDeletePet(
    $filter: ModelSubscriptionPetFilterInput
    $owner: String
  ) {
    onDeletePet(filter: $filter, owner: $owner) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const onCreateContactQuery = /* GraphQL */ `
  subscription OnCreateContactQuery(
    $filter: ModelSubscriptionContactQueryFilterInput
  ) {
    onCreateContactQuery(filter: $filter) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContactQuery = /* GraphQL */ `
  subscription OnUpdateContactQuery(
    $filter: ModelSubscriptionContactQueryFilterInput
  ) {
    onUpdateContactQuery(filter: $filter) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContactQuery = /* GraphQL */ `
  subscription OnDeleteContactQuery(
    $filter: ModelSubscriptionContactQueryFilterInput
  ) {
    onDeleteContactQuery(filter: $filter) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUnavailablity = /* GraphQL */ `
  subscription OnCreateUnavailablity(
    $filter: ModelSubscriptionUnavailablityFilterInput
  ) {
    onCreateUnavailablity(filter: $filter) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUnavailablity = /* GraphQL */ `
  subscription OnUpdateUnavailablity(
    $filter: ModelSubscriptionUnavailablityFilterInput
  ) {
    onUpdateUnavailablity(filter: $filter) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUnavailablity = /* GraphQL */ `
  subscription OnDeleteUnavailablity(
    $filter: ModelSubscriptionUnavailablityFilterInput
  ) {
    onDeleteUnavailablity(filter: $filter) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
