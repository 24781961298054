import React, { Component } from 'react';
import logo from "../../../assets/images/logo-white.png"
import footer from "../../../assets/images/footer-bg.png"
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';

function FooterComponent() {
    const [adminNavLinks, setAdminNavLinks] = React.useState<boolean>(false)
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                let role: string = currentUser.getSignInUserSession()?.getIdToken().payload["cognito:groups"][0]
                setAdminNavLinks(role === 'Admin')

            })
            .catch(() => {
                console.log("Not signed in");

            });

        return () => {
            console.log('This will be logged on unmount');
        };
    }, [authStatus])
    return (
        <>
            {!adminNavLinks ? <><div style={{ height: 80 }}></div><footer className="footer bg-theme1 mt-auto">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="footer-aboutus">
                                <div className="footer-logo">
                                    <a href="/"><img className="img-fluid pb-2" src={logo} alt="" /></a>
                                </div>
                                <div className="footer-aboutus-text">
                                    <p className="font-14 text-white mt-2">Lexi Nation is a Marin County native who grew up in Ross Valley
                                        and attended Redwood High School. She earned a BA in Interdisciplinary Studies from CIIS where
                                        she studied animal-assisted therapy and psychology. She grew up with two rescue dogs and
                                        specializes in anxious and sensitive animals. She volunteered at a wildlife hospital from 2017
                                        to 2020 and also worked at a marine mammal hospital from 2018 to 2021. She currently has a black
                                        cat named Ezra who she enjoys spoiling. In addition to animal care, Lexi provides quality
                                        assurance and
                                        marketing for a nonprofit web site that specializes in social science data aggregation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-links text-white ps-4">
                                <h2 className="pb-2">Important Links</h2>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-unstyled footer-links-list">
                                            <li className="mb-2">
                                                <a className="text-decoration-none font-14" href="/">Home</a>
                                            </li>
                                            <li className="mb-2">
                                                <a className="text-decoration-none font-14" href="/">Book</a>
                                            </li>
                                            <li className="mb-2">
                                                <a className="text-decoration-none font-14" href="/service-rate">Services and rates</a>
                                            </li>
                                            <li className="mb-2">
                                                <a className="text-decoration-none font-14" href="/contact">Contact me</a>
                                            </li>
                                            <li className="mb-2">
                                                <a className="text-decoration-none font-14" href="/policy">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="img-fluid footer-bg" src={footer} alt="" />

            </footer></> : null}
        </>
    )

}
export default FooterComponent;