import React, { useEffect, useState } from 'react';
import { Button, withAuthenticator } from '@aws-amplify/ui-react';
import { getBookingByIdApi, getLoggedInUserId, updateBookingsApi } from '../../services';
import { getLocalData, removeLocalData } from '../../services/global-storage';
import moment from 'moment';
import _ from 'lodash'
import { BookingData } from '../../interfaces';
import { useParams } from 'react-router-dom';
import SiteSpinner from '../../components/UI/Spinner';
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}
type SearchParams = {
    bookingId: string;
}
const BookingConfirmation = () => {
    const { bookingId } = useParams<SearchParams>();
    const [bookingData, setBookingData] = useState<BookingData>()

    const saveCalInvite = () => {
        if (bookingData) {
            let repeatString, repeatEndDate;
            let _tmpStartDate = moment(`${bookingData.bookingDate} ${bookingData.bookingStartTime}`, "YYYY-MM-DD hh:mm a").format("YYYYMMDDTHHmm00");
            let _tmpEndDate = moment(`${bookingData.bookingDate} ${bookingData.bookingEndTime}`, "YYYY-MM-DD hh:mm a").format("YYYYMMDDTHHmm00");
            if (bookingData.repeat) {
                repeatEndDate = moment(`${bookingData.bookingDate} ${bookingData.bookingStartTime}`, "YYYY-MM-DD hh:mm a").add(_.parseInt(bookingData.repeatDuration || ""), _.includes(bookingData.repeatDuration, "w") ? 'weeks' : 'months');
                if (bookingData.repeat === 'daily') repeatEndDate = repeatEndDate.subtract(1, 'day')
                repeatString = `RRULE:FREQ=${_.toUpper(bookingData.repeat)};UNTIL=${repeatEndDate.format("YYYYMMDDTHHmm00")}`;
            }
            let eventDescription = `Your booking ID is ${bookingData?.bookingID}.
            Total cost for your booking is $${bookingData?.bookingAmount}.
            If for some reason your needs change, please let me know at least 24 hours in advance. If you need to contact me, please email lexi@lexination.com or text me at: +1 415-717-9015`
            let url = `BEGIN:VCALENDAR
PRODID:-//Microsoft Corporation//Outlook MIMEDIR//EN
VERSION:2.0
BEGIN:VEVENT
DTSTAMP:20221208T141927Z
DTSTART:${_tmpStartDate}
DTEND:${_tmpEndDate}
${repeatString}
SUMMARY:Lexi Nation pet care
DESCRIPTION:${eventDescription}
LOCATION:NA
PRIORITY:1
END:VEVENT
END:VCALENDAR`

            let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

            if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
                // Open/Save link in IE and Edge
                if (window.navigator.msSaveBlob)
                    window.navigator.msSaveBlob(blob, 'download.ics');
            } else {
                // Open/Save link in Modern Browsers
                window.open(encodeURI("data:text/calendar;charset=utf8," + url));
            }
        }

    }

    const handleUnload = () => {
        // console.log("should fire handleUnload")
        // removeLocalData('bookingId')
    }

    useEffect(() => {
        async function fetchData() {
            if (bookingId) {
                // const currentUser = await getLoggedInUserId()
                // await updateBookingsApi({ id: bookingId, userId: currentUser,owner: currentUser, bookingStatus: 'Active' });
                let bookingData = await getBookingByIdApi(bookingId);
                setBookingData(bookingData)
            }
        }
        fetchData();
        return () => {
            console.log("should fire unload")
        };
    }, [])
    useEffect(() => {
        // window.addEventListener("beforeunload", handleUnload);
        // return () => {
        //     window.removeEventListener("beforeunload", handleUnload);
        // };
    }, []);
    return (
        <div className="container mb-sm-0 mb-4">
            <div className="row justify-content-center">
                <div className="col-7">
                    <h2 className="text-center fontw-600"> <span className="text-theme1">RESERVATION</span> <span className="text-darK">CONFIRMATION</span> </h2>
                    {bookingId && bookingData ? <><div>Great! I’ll see you on {moment(bookingData?.bookingDate).format("DD MMM yyyy")} at {bookingData?.bookingStartTime}.
                        Your booking ID is {bookingData?.bookingID}.
                        The total cost for each booking is ${bookingData?.bookingAmount}.
                        If for some reason your needs change, please let me know at least 24 hours in advance. If you need to contact me, please email&nbsp;<a href="mailto:lexi@lexination.com">lexi@lexination.com</a> or text me at <a href="sms:4157179015">415-717-9015</a>. Thank you! <br />

                    </div>
                        <br />
                        <p>
                            <div className="row justify-content-center all-booking">
                                {!_.isEmpty(bookingData) ? <Button
                                    loadingText=""
                                    onClick={saveCalInvite}
                                    ariaLabel=""
                                >Add to Calendar
                                </Button> : null}
                            </div>
                        </p></> : <SiteSpinner />}

                </div>
            </div>
        </div>
    );

}

export default BookingConfirmation;