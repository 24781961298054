import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { withAuthenticator, Button, Heading, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Col, Row } from 'react-bootstrap';
import logo from "../../../assets/images/logo-white.png"
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import * as React from 'react';
import { getLocalData, storeLocalData } from '../../../services/global-storage';

import { Auth } from 'aws-amplify';

function Toolbar(props: any) {
    const location = useLocation();
    const [adminNavLinks, setAdminNavLinks] = React.useState<boolean>(false)
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { route } = useAuthenticator(context => [context.route]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const gotoSign = () => {
        props.history.push("/signin")
    }

    const gotoSignOut = () => {
        signOut()
        props.history.push("/signin")
    }
    const gotoMyBooking = () => {
        props.history.push("/my-bookings")
    }
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                let role: string | undefined = _.first(currentUser.getSignInUserSession()?.getIdToken().payload["cognito:groups"])
                setAdminNavLinks(role === 'Admin')
                storeLocalData('isLoggedIn', "Y")
            })
            .catch(e => {
                setAdminNavLinks(false)
                console.log("Not signed in:", e);
                storeLocalData('isLoggedIn', "N")
            });

        return () => {
            console.log('This will be logged on unmount');
        };
    }, [authStatus])
    return (<>
        <header>
            <div className="top-bar bg-theme1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 d-none d-lg-block">
                            <div className="header-top-email">
                                <a className="text-white text-decoration-none font-14 ps-5" href="mailto:lexi@lexination.com">lexi@lexination.com</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-lg-3 col-4">
                            <div className="logo text-center">
                                <a href="/">
                                    <img className="img-fluid" src={logo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-lg-5 col-8">
                            <div className="header-top-bar-btn text-end color-white">

                                {route === 'authenticated' ? <>
                                    <small className='color-white welcome-text'>Welcome <span className='welcome-name'> <b>{user?.attributes?.name || _.truncate(user?.attributes?.email, {
                                        'length': 12,
                                        'omission': '...'
                                    })}</b></span> &nbsp;</small>
                                    <Button size={"small"} onClick={gotoSignOut}>Sign out</Button>
                                </> :
                                    <button className="btn" onClick={gotoSign}>Sign In</button>}
                                &nbsp; <a className="header-call-btn" href="tel:4157179015">415-717-9015</a>
                            </div>
                        </div>
                        {/* {route === 'authenticated' && user ?<pre>{JSON.stringify(,null,2)}</pre>:null} */}
                    </div>
                </div>
            </div>

            <Nav activeKey={location.pathname} className="navbar navbar-expand-lg main-menu header container mt-md-3 mb-md-3 header">
                <div className="container-fluid d-flex justify-content-end px-lg-0 px-2">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {
                            (!adminNavLinks) ? <ul className="navbar-nav me-auto  mb-lg-0 main-menu">
                                <li className="nav-item"><Nav.Link href="/">Home</Nav.Link></li>
                                {route === 'authenticated' ? <><li><Nav.Link href="/my-bookings">My Bookings</Nav.Link></li>
                                    <li><Nav.Link href="/edit-profile">Profile</Nav.Link></li>
                                </> : null}
                                <li className="nav-item"><Nav.Link href="/service-rate">Services and Rates</Nav.Link></li>
                                <li className="nav-item"><Nav.Link href="/contact">Contact Me</Nav.Link></li>
                                {location.pathname !== "/" &&
                                    <li className="nav-item float-md-end boot-btn-add">
                                        <a className="bookbtn" href="index.html">
                                            <div className="input-group main-menu-bookbtn">
                                                <span className="form-control"></span>
                                                <button className="btn" type="button" id="button-addon2">Book</button>
                                            </div>
                                        </a>
                                    </li>
                                }</ul> : <ul className="navbar-nav me-auto  mb-lg-0  main-menu">
                                <li className="nav-item"><Nav.Link href="/">Home</Nav.Link></li>
                                {route === 'authenticated' ? <><li className="nav-item">
                                    <Nav.Link href="/all-bookings">All Bookings</Nav.Link>
                                </li>
                                    <li className="nav-item"><Nav.Link href="/event-calendar">Event Calendar</Nav.Link></li>
                                    <li className="nav-item"><Nav.Link href="/all-users">Users</Nav.Link></li>
                                    <li className="nav-item"><Nav.Link href="/all-queries">Contact Queries</Nav.Link></li>

                                </> : null}
                            </ul>
                        }
                    </div>
                </div>
            </Nav>


        </header>
    </>
    );
}

export default Toolbar;